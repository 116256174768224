<template>
  <vca-row>
    <div v-if="filters.includes('crew')">
      <h4>{{ $t("email.membership.active.label") }}</h4>
      <vca-checkbox v-model="filter_all_active" id="all">
        <span class="bold" v-if="!filter_all_active">{{
          $t("email.membership.active.all")
        }}</span>
        <span class="bold" v-else>{{
          $t("email.membership.active.none")
        }}</span>
        <div class="inline-infobox">
          <vca-info-box>{{
            $t("email.membership.active.filter_info")
          }}</vca-info-box>
        </div>
      </vca-checkbox>
      <vca-checkbox v-model="value.active_state" id="confirmed">{{
        $t("email.membership.active.confirmed")
      }}</vca-checkbox>
      <vca-checkbox v-model="value.active_state" id="requested">{{
        $t("email.membership.active.requested")
      }}</vca-checkbox>
      <vca-checkbox v-model="value.active_state" id="rejected">{{
        $t("email.membership.active.rejected")
      }}</vca-checkbox>
      <vca-checkbox v-model="value.active_state" id="inactive">{{
        $t("email.membership.active.inactive")
      }}</vca-checkbox>
    </div>
    <div v-if="filters.includes('crew')">
      <h4>{{ $t("email.membership.nvm.label") }}</h4>
      <vca-checkbox v-model="filter_all_nvm" id="all">
        <span class="bold" v-if="!filter_all_nvm">{{
          $t("email.membership.nvm.all")
        }}</span>
        <span class="bold" v-else>{{ $t("email.membership.nvm.none") }}</span>
        <div class="inline-infobox">
          <vca-info-box>{{
            $t("email.membership.nvm.filter_info")
          }}</vca-info-box>
        </div>
      </vca-checkbox>
      <vca-checkbox v-model="value.nvm_state" id="confirmed">{{
        $t("email.membership.nvm.confirmed")
      }}</vca-checkbox>
      <vca-checkbox v-model="value.nvm_state" id="expired">{{
        $t("email.membership.nvm.expired")
      }}</vca-checkbox>
      <vca-checkbox v-model="value.nvm_state" id="inactive">{{
        $t("email.membership.nvm.inactive")
      }}</vca-checkbox>
    </div>
    <div v-if="filters.includes('event')">
      <h4>{{ $t("email.event.label") }}</h4>
      <vca-checkbox v-model="filter_all_event_states" id="all">
        <span class="bold" v-if="!filter_all_event_states">{{
          $t("email.event.filter.all")
        }}</span>
        <span class="bold" v-else>{{ $t("email.event.filter.none") }}</span>
        <div class="inline-infobox">
          <vca-info-box>{{
            $t("email.event.filter.filter_info")
          }}</vca-info-box>
        </div>
      </vca-checkbox>
      <vca-checkbox v-model="value.event_state" id="confirmed">{{
        $t("email.event.group.confirmed")
      }}</vca-checkbox>
      <vca-checkbox v-model="value.event_state" id="rejected">{{
        $t("email.event.group.rejected")
      }}</vca-checkbox>
      <vca-checkbox v-model="value.event_state" id="requested">{{
        $t("email.event.group.requested")
      }}</vca-checkbox>
    </div>
  </vca-row>
</template>
<script>
export default {
  name: "EmailFilter",
  data() {
    return {
      filter_all_active: false,
      filter_all_nvm: false,
      filter_all_event_states: false,
    };
  },
  props: {
    value: {
      type: Object,
      default: null,
    },
    filters: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  watch: {
    filter_all_active: function (nVal) {
      this.value.active_state = nVal
        ? ["confirmed", "requested", "rejected", "inactive"]
        : [];
    },
    filter_all_nvm: function (nVal) {
      this.value.nvm_state = nVal ? ["confirmed", "expired", "inactive"] : [];
    },
    filter_all_event_states: function (nVal) {
      this.value.event_state = nVal
        ? ["confirmed", "rejected", "requested"]
        : [];
    },
  },
};
</script>
