<template>
  <vca-card>
    <h2>{{ $t("email.list.header") }}</h2>
    <vca-card class="shadowed vca-center">
      <EmailTable />
      <vca-popup
        v-if="current != null"
        :show="current != null"
        :title="$t('email.popup.view')"
        @close="current = null"
      >
        <EmailPreview
          v-model="current"
          :preview="false"
          :sendable="false"
          :deleteable="false"
        />
      </vca-popup>
    </vca-card>
  </vca-card>
</template>

<script>
import { mapGetters } from "vuex";
import EmailPreview from "@/components/emails/EmailPreview";
import EmailTable from "@/components/account/emails/AccountEmailsTable";
export default {
  name: "AccountEmails",
  components: { EmailTable, EmailPreview },
  computed: {
    ...mapGetters({
      user: "user/current",
    }),
    current: {
      get() {
        return this.$store.state.user.emails.mailbox.message.current;
      },
      set(value) {
        this.$store.commit("user/emails/mailbox/message/current", value);
      },
    },
  },
  created() {
    this.current = null;
    var id = { mailbox_id: this.user.mailbox_id };

    this.$store.commit("user/emails/mailbox/id", id);
    this.$store.dispatch("user/emails/mailbox/get");
    this.$store.dispatch("crews/public");
    this.$store.dispatch("events/public");
  },
};
</script>
